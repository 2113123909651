import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useAccount from '../../hooks/useAccount';
import AppBar from '@mui/material/AppBar';
import useTheme from '@mui/material/styles/useTheme';
import Toolbar from '@mui/material/Toolbar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import Users from '../users/Users';
import BrandSettings from '../settings/BrandSettings';
import NotificationSettings from '../settings/NotificationSettings';
import GeneralSettings from '../settings/GeneralSettings';

const AccountSettings = () => {
    const theme = useTheme();
    const [value, setValue] = useState('general');
    const { userLimit, userPermissions } = useAccount();
    const canManageUsers = userPermissions.includes('Manage Users');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: theme.zIndex.appBar + 1 }}>
                <Toolbar>
                    <Typography variant="h6">
                        My Account
                    </Typography>
                </Toolbar>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="General" value="general" />
                    <Tab label="Branding" value="branding" />
                    {userLimit > 1 && canManageUsers && <Tab label="Users" value="userLimit" />}
                    <Tab label="Notifications" value="notification" />
                </Tabs>
            </AppBar>
            <Box>
                <TabContext value={value}>
                    <TabPanel value="general"><GeneralSettings /></TabPanel>
                    <TabPanel value="branding"><BrandSettings /></TabPanel>
                    {userLimit > 1 && canManageUsers && <TabPanel value="userLimit"><Users /></TabPanel>}
                    <TabPanel value="notification"><NotificationSettings /></TabPanel>
                </TabContext>
            </Box>
        </>
    );
};

export default AccountSettings;