import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useApi from '../../hooks/useApi';
import useAccount from '../../hooks/useAccount';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery } from '@mui/material';
import InviteUserDialog from './dialogs/InviteUserDialog';
import EditUserDialog from './dialogs/EditUserDialog';
import DeleteUserDialog from './dialogs/DeleteUserDialog';
import Typography from '@mui/material/Typography';
import AlertBar from '../../alertBar/AlertBar';
import useSnackbar from '../../hooks/useSnackbar';
import { format } from 'date-fns';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { grey } from '@mui/material/colors';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import UserChartDonut from './charts/UserChartDonut';
import useTheme from '@mui/material/styles/useTheme';

const greyColor = grey[600];

const calculateLuminance = ({ r, g, b }) => (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
};

const getTextColour = (backgroundColour, lightColour, darkColour) => {
    const rgb = hexToRgb(backgroundColour);

    const luminance = calculateLuminance(rgb);

    return luminance < 0.3 ? darkColour : lightColour;
};

const dialogs = {
    INVITE_USER: 0,
    EDIT_USER: 1,
    DELETE_USER: 2
};

const cellStyles = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
};

const UserActionIcons = ({ name, action }) => {
    const iconMapping = {
        'Edit': <ModeEditIcon />,
        'Delete': <DeleteIcon />
    };

    return (
        <Button sx={{ marginRight: 1, color: greyColor }} variant="text" onClick={action} startIcon={iconMapping[name]}>
            {name}
        </Button>
    );
};

const Users = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dialog, setDialog] = useState(null);
    const [users, setUsers] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const { userLimit, emailAddress } = useAccount();
    const { showSnackbar } = useSnackbar();
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));

    const getUsers = async () => {
        const response = await handleGet('users');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setUsers(data);
        setIsLoading(false);
    };

    const handleCloseDialog = () => setDialog(null);

    // add user

    const handleAddUser = async user => {
        handleCloseDialog();
        setIsLoading(true);

        const response = await handlePost('users', user);

        if (response.ok) {
            getUsers();
        }
        else {
            const data = await response.json();

            showSnackbar(data.message, 'error');
            setIsLoading(false);
        }
    };

    // edit user

    const handleOpenEditUserDialog = user => {
        setSelectedUser(user);
        setDialog(dialogs.EDIT_USER);
    };

    const handleCloseEditUserDialog = () => {
        handleCloseDialog();
        setSelectedUser(null);
    };

    const handleEditUser = async user => {
        handleCloseEditUserDialog();
        setIsLoading(true);
        const response = await handlePut(`users/${selectedUser.id}`, user);

        if (response.status === 403) {
            showSnackbar('You cannot complete the action', 'error');
        }

        getUsers();
    };

    // delete user

    const handleOpenDeleteUserDialog = user => {
        setSelectedUser(user);
        setDialog(dialogs.DELETE_USER);
    };

    const handleCloseDeleteUserDialog = () => {
        handleCloseDialog();
        setSelectedUser(null);
    };

    const handleDeleteUser = async () => {
        handleCloseDeleteUserDialog();
        setIsLoading(true);
        await handleDelete(`users/${selectedUser.id}`);
        getUsers();
    };

    useEffect(() => {
        getUsers();
    }, []);

    const UserMenu = ({ onEdit, onDelete }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = e => {
            e.preventDefault();
            e.stopPropagation();

            setAnchorEl(e.currentTarget);
        };

        const handleClose = e => {
            e.preventDefault();
            e.stopPropagation();

            setAnchorEl(null);
        };

        const handleAction = (e, action) => {
            e.stopPropagation();

            action();
            setAnchorEl(null);
        };

        return (
            <>
                <IconButton onClick={handleClick} size="small" sx={{ marginRight: 1 }}>
                    <MoreVertIcon fontSize="small" />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem onClick={e => handleAction(e, onEdit)}>
                        <ListItemIcon>
                            <ModeEditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={e => handleAction(e, onDelete)}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </Menu>
            </>
        );
    };

    const UsersTable = () => {
        if (users.length > 0) {
            return (
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {!isSmallScreen ? (
                                    <>
                                        <TableCell component="th" sx={{ ...cellStyles, width: 75 }}>Avatar</TableCell>
                                        <TableCell component="th" sx={{ ...cellStyles, minWidth: 250 }}>Name</TableCell>
                                        <TableCell component="th" sx={{ ...cellStyles, width: '25%' }}>Email Address</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Provider</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Added</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Last Login</TableCell>
                                        <TableCell component="th" sx={cellStyles}>User Permissions</TableCell>
                                        <TableCell component="th" sx={cellStyles} align="right" />
                                        <TableCell component="th" sx={cellStyles} align="right" />
                                        <TableCell component="th" sx={cellStyles} align="right" />
                                    </>
                                ) : (
                                    <>
                                        <TableCell component="th" sx={{ ...cellStyles, minWidth: 250 }}>Name</TableCell>
                                        <TableCell component="th" sx={{ ...cellStyles, width: '25%' }}>Email Address</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Added</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Last Login</TableCell>
                                        <TableCell component="th" sx={cellStyles}>Permissions</TableCell>
                                        <TableCell component="th" sx={cellStyles} align="right" />
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user => {

                                return (
                                    <TableRow key={user.id}>
                                        {!isSmallScreen ? (
                                            <>
                                                <TableCell><Avatar alt={user.name} src={user.avatarURL} /></TableCell>
                                                <TableCell sx={cellStyles}>{user.name ?? '-'}</TableCell>
                                                <TableCell sx={cellStyles}>{user.emailAddress}</TableCell>
                                                <TableCell sx={cellStyles}>{user.provider ?? 'Username & Password'}</TableCell>
                                                <TableCell sx={cellStyles}>{format(new Date(user.createdDateTime), 'dd/MM/yyyy HH:mm')}</TableCell>
                                                <TableCell>
                                                    {user.lastLoggedIn ? format(new Date(user.lastLoggedIn), 'dd/MM/yyyy HH:mm') : ('-')}
                                                </TableCell>
                                                <TableCell sx={cellStyles}>
                                                    {user.permissions.length > 0 ? (
                                                        user.permissions.map((p, index) => (
                                                            <Chip
                                                                key={index}
                                                                label={p.label}
                                                                size="small"
                                                                sx={{ marginLeft: 1, backgroundColor: p.colour, color: getTextColour(p.colour, theme.palette.getContrastText(p.colour), theme.palette.getContrastText(p.colour)) }}
                                                            />
                                                        ))
                                                    ) : '-'}
                                                </TableCell>
                                                <TableCell sx={{ ...cellStyles, padding: 0 }} align="right">
                                                    {user.emailAddress !== emailAddress && (
                                                        <UserActionIcons name="Edit" action={() => handleOpenEditUserDialog(user)} />
                                                    )}
                                                </TableCell>
                                                <TableCell sx={{ ...cellStyles, padding: 0 }} align="right">
                                                    {user.emailAddress !== emailAddress && (
                                                        <UserActionIcons name="Delete" action={() => handleOpenDeleteUserDialog(user)} />
                                                    )}
                                                </TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell sx={{ ...cellStyles, maxWidth: 350 }}>
                                                    <Chip
                                                        avatar={<Avatar alt={user.name} src={user.avatarURL} />}
                                                        label={user.name ?? '-'}
                                                        variant="outlined"
                                                        sx={{ height: 'auto', border: 'none', fontSize: '14px', '& .MuiChip-label': { display: 'block', whiteSpace: 'normal' } }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={cellStyles}>
                                                    {user.emailAddress}
                                                    <Tooltip arrow title={`Provider : ${user.provider ?? 'Username & Password'}`}>
                                                        <Chip sx={{ marginLeft: 2 }} label={user.provider ?? 'Username & Password'} size="small" variant="outlined" />
                                                    </Tooltip>
                                                </TableCell>
                                                <Tooltip arrow title={format(new Date(user.createdDateTime), 'dd/MM/yyyy HH:mm')}>
                                                    <TableCell sx={cellStyles}>
                                                        {format(new Date(user.createdDateTime), 'dd/MM/yyyy')}
                                                    </TableCell>
                                                </Tooltip>
                                                <Tooltip arrow title={user.lastLoggedIn ? format(new Date(user.lastLoggedIn), 'dd/MM/yyyy HH:mm') : ''}>
                                                    <TableCell>
                                                        {user.lastLoggedIn ? format(new Date(user.lastLoggedIn), 'dd/MM/yyyy HH:mm') : ('-')}
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell sx={cellStyles}>
                                                    {user.permissions.length > 0 ? (
                                                        user.permissions.map((p, index) => (
                                                            <Chip
                                                                key={index}
                                                                label={p.label}
                                                                size="small"
                                                                sx={{ marginLeft: 1, backgroundColor: p.colour, color: getTextColour(p.colour, theme.palette.getContrastText(p.colour), theme.palette.getContrastText(p.colour)) }}
                                                            />
                                                        ))
                                                    ) : '-'}
                                                </TableCell>
                                                <TableCell sx={cellStyles} align="right">
                                                    {user.emailAddress !== emailAddress && (
                                                        <UserMenu
                                                            onEdit={() => handleOpenEditUserDialog(user)}
                                                            onDelete={() => handleOpenDeleteUserDialog(user)}
                                                        />
                                                    )}
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }

        return (
            <Typography sx={{ p: 2 }}>
                You have no users in your account.
            </Typography>
        );
    };

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    const availableSeats = Math.max(userLimit - users.length, 0);

    return (
        <>
            {availableSeats > 0 ? (
                <AlertBar severity="info" positionTop={128}>
                    You have <strong>{availableSeats}</strong> {availableSeats === 1 ? 'user' : 'users'} remaining.
                </AlertBar>
            ) : (
                <AlertBar severity="warning" positionTop={128}>
                    You have reached your user limit - contact support on <strong><a href="tel:01392447200">01392 447 200</a></strong> if you need more.
                </AlertBar>
            )}
            <Divider />

            <Paper sx={{ mt: 2 }}>
                <Toolbar
                    variant="dense"
                    disableGutters
                    sx={{ px: 2 }}
                >
                    <div style={{ flexGrow: 1 }}>
                        <Typography variant="subtitle2">
                            Users
                        </Typography>
                    </div>
                    <Button
                        size="small"
                        onClick={() => setDialog(dialogs.ADD_FIELD)}
                        startIcon={<AddIcon />}
                        disabled={availableSeats === 0}
                    >
                        Invite User
                    </Button>
                </Toolbar>
                <Divider />
                <UsersTable availableSeats={availableSeats} />
            </Paper>
            <Paper sx={{ mt: 2 }}>
                <UserChartDonut users={users} userLimit={userLimit} />
            </Paper>

            {dialog === dialogs.ADD_FIELD && (
                <InviteUserDialog
                    onSubmit={handleAddUser}
                    onClose={handleCloseDialog}
                />
            )}

            {(selectedUser && dialog === dialogs.EDIT_USER) && (
                <EditUserDialog
                    user={selectedUser}
                    onSubmit={handleEditUser}
                    onClose={handleCloseEditUserDialog}
                />
            )}
            {(selectedUser && dialog === dialogs.DELETE_USER) && (
                <DeleteUserDialog
                    user={selectedUser}
                    onSubmit={handleDeleteUser}
                    onClose={handleCloseDeleteUserDialog}
                />
            )}
        </>
    );
};

export default Users;