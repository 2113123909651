import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useApi from '../../hooks/useApi';
import SmtpTestResultsDialog from '../smtpSettings/dialogs/SmtpTestResultsDialog';
import Card from '@mui/material/Card';
import AlertBar from '../../alertBar/AlertBar';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import useAccount from '../../hooks/useAccount';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const cellStyles = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
};

const SmtpSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [settings, setSettings] = useState();
    const [smtpUsername, setSmtpUsername] = useState('');
    const [smtpPassword, setSmtpPassword] = useState('');
    const [isSmtpTestSubmitting, setIsSmtpTestSubmitting] = useState(false);
    const [smtpTestResult, setSmtpTestResult] = useState();
    const [smtpTestResultDialogIsOpen, setSmtpTestResultDialogIsOpen] = useState(false);
    const { handleGet, handlePut, handlePost } = useApi();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const { isInternalComms } = useAccount();
    const [account, setAccount] = useState();

    const handleFetchAccount = async () => {
        setIsLoading(true);
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            setIsSubmitting(false);
            return;
        }

        const data = await response.json();

        setAccount(data);

        setIsLoading(false);
        setIsSubmitting(false);
    };

    const getSettings = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setSettings(data);
        setSmtpUsername(data.smtpUsername);
        setIsLoading(false);
    };

    const onTestSmtpSettings = async () => {
        setIsSmtpTestSubmitting(true);

        const response = await handlePost('account/test-smtp-settings', { smtpUsername, smtpPassword });

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        setIsSmtpTestSubmitting(false);
        setSmtpTestResult(data);
        setSmtpTestResultDialogIsOpen(true);
    };

    const onSubmit = async e => {
        e.preventDefault();

        setIsSubmitting(true);

        await handlePut('account', { smtpUsername, smtpPassword });

        // refresh
        await getSettings();

        setIsSubmitting(false);
    };

    useEffect(() => {
        const getData = async () => {
            await getSettings();
            await handleFetchAccount();
        };

        getData();
    }, []);

    useEffect(() => {
        setHasUnsavedChanges(smtpUsername && smtpUsername !== settings.smtpUsername
            || smtpPassword && smtpPassword !== settings.smtpPassword
        );
    }, [settings, smtpPassword, smtpUsername]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AlertBar
                shown={isLoading || hasUnsavedChanges}
                action={(
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Save Changes
                    </Button>
                )}
                positionTop={128}
            >
                {isLoading ? 'Updating...' : 'You have unsaved changes.'}
            </AlertBar>
            <Card>
                <CardContent sx={{ p: 0 }}>
                    <Toolbar variant="dense" disableGutters sx={{ px: 2, display: 'flex' }}>
                        <Typography component="h3" variant="h6" sx={{ flexGrow: 1 }}>SMTP</Typography>
                        <Button onClick={onTestSmtpSettings}>
                            {isSmtpTestSubmitting ? 'Testing' : 'Test'}
                        </Button>
                    </Toolbar>
                    <Divider />
                    <Box sx={{ px: 2 }}>
                        <form onSubmit={onSubmit} noValidate autoComplete="off">
                            <TextField value={settings.smtpHost} type="text" label="Host" disabled={true} fullWidth={true} />
                            <TextField value={settings.smtpPort} type="text" label="Port" disabled={true} fullWidth={true} />
                            <TextField value={smtpUsername} onChange={e => setSmtpUsername(e.target.value)} required type="email" label="Username" fullWidth={true} />
                            <TextField value={smtpPassword} onChange={e => setSmtpPassword(e.target.value)} required type="text" label="Change password" fullWidth={true} />
                        </form>
                    </Box>
                </CardContent>
            </Card>

            <Card sx={{ mt: 2 }}>
                <Toolbar variant="dense" disableGutters sx={{ px: 2 }}>
                    <div style={{ flexGrow: 1 }}>
                        <Typography component="h3" variant="h6">Safe Senders</Typography>
                    </div>
                </Toolbar>
                <Divider />
                {account?.safeSenders.length > 0 ? (
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {account.safeSenders.map(emailAddress => (
                                    <TableRow key={emailAddress}>
                                        <TableCell sx={cellStyles}>{emailAddress}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box sx={{ p: 2 }}>
                        <Typography variant="body2">This account has no safe senders set up.</Typography>
                    </Box>
                )}
            </Card>

            {(isInternalComms && account?.allowedRecipients?.length > 0) && (
                <Paper sx={{ mt: 2 }}>
                    <Box>
                        <Typography sx={{ mx: 2, pt: 1 }} component="h3" variant="h6" gutterBottom>Allowed Recipients</Typography>
                        <Divider />
                        <Box sx={{ p: 1 }}>
                            {account.allowedRecipients.map(recipient => <Chip key={recipient.id} label={recipient.pattern} sx={{ mr: 1 }} />)}
                        </Box>
                    </Box>
                </Paper>
            )}
            {smtpTestResult && (
                <SmtpTestResultsDialog
                    open={smtpTestResultDialogIsOpen}
                    onClose={() => setSmtpTestResultDialogIsOpen(false)}
                    succeeded={smtpTestResult.succeeded}
                    message={smtpTestResult.message}
                />
            )}
            {(isSubmitting || isSmtpTestSubmitting) && (
                <LoadingOverlay />
            )}
        </>
    );
};

export default SmtpSettings;